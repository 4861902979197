import { useEffect } from "react"

const FaqPage = () => {
  useEffect(() => {
    window.location = "https://atm.atlassian.net/servicedesk/customer/portals"
  }, [])

  return null
}

export default FaqPage
